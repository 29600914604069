//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInteractor {
  //#region Constructor

  constructor(private readonly httpService: HttpService) {}

  //#endregion

  //#region Methods

  public async login(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.httpService.post<TokenProxy>(
      environment.routes.auth.login,
      payload
    );
  }

  public deleteAccount(): Promise<AsyncResult<void>> {
    const url = environment.routes.user.getMe;
    return this.httpService.delete(url);
  }

  //#endregion
}
