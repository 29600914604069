//#region  Imports

import { InjectionToken } from '@angular/core';
import { Notyf } from 'notyf';

//#endregion

//#region Const

export const NOTYF = new InjectionToken<Notyf>('NotyfToken');

//#endregion

//#region Function

export function notyfFactory(): Notyf {
  return new Notyf({
    duration: 5000,
    position: {
      x: 'right',
      y: 'top',
    },
    ripple: true,
    dismissible: true,
  });
}

//#endregion
