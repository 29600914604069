//#region Imports

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { StorageService } from '../../services/storage/storage.service';
import { UserService } from '../../services/user/user.service';
import { httpPrefixToLink } from '../../utils/functions/functions';

//#endregion

export const AuthGuard = async (route: ActivatedRouteSnapshot) => {
  const storage = inject(StorageService);
  const authentication = inject(AuthenticationService);
  const router = inject(Router);

  const {
    unprotectedRoute,
    protectedRoute,
    routeToRedirect,
    routeToRedirectIfSuccess,
    isExternalUrl,
  } = route.data;

  if (!routeToRedirect)
    return true;

  const token = await storage.get<TokenProxy>(environment.storageKeys.userToken).then(result => result.success);
  const userFromStorage = await storage.get<UserProxy>(environment.storageKeys.userInformation);

  if (userFromStorage.success)
    await authentication.updateLoggedUser(userFromStorage.success);

  if (token && protectedRoute) {
    if (routeToRedirectIfSuccess)
      return void await router.navigateByUrl(routeToRedirect);

    return true;
  }

  if (!token && unprotectedRoute) {
    if (routeToRedirectIfSuccess)
      return void await router.navigateByUrl(routeToRedirect);

    return true;
  }

  if (isExternalUrl) {
    return void window.open(httpPrefixToLink(routeToRedirect), '_self');
  }

  if (isExternalUrl)
    window.location.href = routeToRedirect;

  return void await router.navigateByUrl(routeToRedirect);
};
