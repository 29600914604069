//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CreateCompanyProjectPayload } from '../../models/payloads/create-company-project.payload';
import { UpdateCompanyPayload } from '../../models/payloads/update-company.payload';
import { CompanyNameProxy, CompanyProxy } from '../../models/proxies/company.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class CompanyInteractor {

  //#region Constructor

  constructor(private readonly http: HttpService) {}

  //#endregion

  //#region Public methods

  public getById(id: number): Promise<AsyncResult<CompanyProxy>> {
    return this.http.get<CompanyProxy>(
      environment.routes.company.byId.replace('{id}', id.toString()),
    );
  }

  public update(id: number, payload: UpdateCompanyPayload): Promise<AsyncResult<CompanyProxy>> {
    return this.http.put<CompanyProxy>(
      environment.routes.company.update.replace('{id}', id.toString()),
      payload,
    );
  }

  public async createCompanyProject(payload: CreateCompanyProjectPayload): Promise<AsyncResult<void>> {
    const url = environment.routes.company.project;

    return await this.http.post(url, payload);
  }

  public async getName(companyIdentification: number | string): Promise<AsyncResult<CompanyNameProxy>> {
    const url = environment.routes.company.name.replace('{id}', companyIdentification.toString());
    return await this.http.get(url);
  }

  //#endregion

}
