import { academicCategories } from '../../models/enums/category.enum';
import { ProjectProxy } from '../../models/proxies/project.proxy';

export function openLinkInAnotherTab(url: string, window: WindowProxy): void {
  window.open(httpPrefixToLink(url), '_blank');
}

export function openLinkInSameTab(url: string, window: WindowProxy): void {
  window.open(httpPrefixToLink(url), '_self');
}

export function httpPrefixToLink(url: string): string {
  const HTTP_PREFIX: RegExp = /^http(s)?:\/\//i;

  if (!HTTP_PREFIX.test(url))
    url = `https://${ url }`;

  return url;
}

export function isAcademicProject(project: ProjectProxy): boolean {
  return academicCategories.includes(project.category);
}

export function hoursAdder(date: Date, hours: number): Date {
  date.setHours(date.getHours() + hours);

  return date;
}
