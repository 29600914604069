<div class="box">
  <span class="loader"
        [class.loader-white]="true"
        [style.--size.rem]="sizeInRem">
  </span>

  <cnt-icon *ngIf="showIcon"
            [style.--size.rem]="(sizeInRem/2)"
            src="assets/icons/cf.svg"
            alt="Icone Loading"></cnt-icon>

  <div *ngIf="backdrop" class="backdrop"></div>
</div>
