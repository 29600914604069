import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../components/button/button.module';
import { CookieComponent } from './cookie.component';

@NgModule({
  declarations: [CookieComponent],
  exports: [CookieComponent],
  imports: [
    CommonModule,
    ButtonModule,
  ],
})
export class CookieModule {}
