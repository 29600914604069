<button [ngClass]="style"
        [disabled]="disabled || isLoading"
        [type]="type"
>
  <ng-container *ngIf="!isLoading">
    <ng-content></ng-content>
  </ng-container>

  <ng-container *ngIf="isLoading" >
    <cnt-loading-spinner [showIcon]="true"></cnt-loading-spinner>
  </ng-container>
</button>
