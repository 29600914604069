import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideDialogConfig } from '@ngneat/dialog';
import { IConfig, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SentryErrorHandler } from './errors/sentry.error-handler';
import { NOTYF, notyfFactory } from './factory/notyf/notyf.factory';
import { CookieModule } from './modals/cookie/cookie.module';
import { HttpModule } from './modules/http/http.module';
import { BaseUrlInterceptor } from './modules/http/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http/interceptors/bearer-token.interceptor';
import { RefreshTokenInterceptor } from './modules/http/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http/interceptors/retry.interceptor';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      bearerTokenKey: environment.storageKeys.userToken,
    }),
    IonicStorageModule.forRoot({
      name: 'Conecta',
      description: 'Storage da plataforma Conecta',
      storeName: '__conecta',
      dbKey: '__conecta-key',
      driverOrder: [
        Drivers.IndexedDB,
        Drivers.SecureStorage,
        Drivers.LocalStorage,
      ],
    }),
    CookieModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: NOTYF,
      useFactory: notyfFactory,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    provideDialogConfig({
      closeButton: false,
      enableClose: false,
      backdrop: true,
    }),
    provideNgxMask(maskConfigFunction),
    provideEnvironmentNgxMask(maskConfigFunction),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
