//#region Imports

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { ChangePasswordPayload } from '../../models/payloads/change-password.payload';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { InviteUserPayload } from '../../models/payloads/invite-user.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { ForgotPasswordProxy } from '../../models/proxies/forgot-password.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { CrudRequestParams } from '../../utils/crud/crud';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {

  //#region Constructor

  constructor(
    private readonly interactor: UserInteractor,
    private readonly errorService: ErrorService,
    private readonly storageService: StorageService,
  ) {
    this.storageService.get<UserProxy>(environment.storageKeys.userInformation).then(result => {
      if (result.success)
        this.setUser(result.success);
    });
  }

  //#endregion

  //#region Properties

  private readonly userSubject: BehaviorSubject<UserProxy | null> = new BehaviorSubject<UserProxy | null>(null);

  //#endregion

  //#region Methods

  public getUser(): UserProxy | null {
    return this.userSubject.getValue();
  }

  public getUser$(): Observable<UserProxy | null> {
    return this.userSubject.asObservable();
  }

  public setUser(user: UserProxy): void {
    Sentry.setUser({
      id: `${ user.id }`,
      email: user.email,
    });

    this.userSubject.next(user);
    this.storageService.set(environment.storageKeys.userInformation, user);
  }

  public clearUser(): void {
    Sentry.setUser(null);

    this.userSubject.next(null);
  }

  public async get(crudOptions?: CrudRequestParams<UserProxy>): Promise<UserProxy[]> {
    const { success, error } = await this.interactor.getAll(crudOptions);

    if (!success)
      throw (this.errorService.getErrorMessage(error));

    return Array.isArray(success) ? success : success.data;
  }

  public async getMe(): Promise<UserProxy> {
    const { success, error } = await this.interactor.getMe();

    if (!success)
      throw (this.errorService.getErrorMessage(error));

    return success;
  }

  public async create(payload: CreateUserPayload): Promise<UserProxy> {
    const { success: createdUser, error } = await this.interactor.create(payload);

    if (!createdUser)
      throw (this.errorService.getErrorMessage(error));

    return createdUser;
  }

  public async update(id: number, payload: UpdateUserPayload): Promise<UserProxy> {
    const { success: updatedUser, error } = await this.interactor.update(id, payload);

    if (!updatedUser)
      throw (this.errorService.getErrorMessage(error));

    this.setUser(updatedUser);
    return updatedUser;
  }

  public async invite(payload: InviteUserPayload): Promise<UserProxy> {
    const { success: createdUser, error } = await this.interactor.invite(payload);

    if (!createdUser)
      throw (this.errorService.getErrorMessage(error));

    return createdUser;
  }

  public async recoverPasswordByEmail(email: string): Promise<ForgotPasswordProxy> {
    const { success, error } =
      await this.interactor.recoverPasswordByEmail(email);

    if (!success)
      throw (this.errorService.getErrorMessage(error));

    return success;
  }

  public async resetPassword(payload: ChangePasswordPayload): Promise<boolean> {
    const { error } = await this.interactor.resetPassword(payload);

    if (error)
      throw (this.errorService.getErrorMessage(error));

    return true;
  }

  public async verifyCode(code: string): Promise<boolean> {
    const { error } = await this.interactor.verifyCode(code);

    if (error)
      throw (this.errorService.getErrorMessage(error));

    return true;
  }

  //#endregion

}
