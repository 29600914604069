//#region Imports

import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AcceptedInterface } from '../../models/interfaces/accepted.interface';
import { StorageService } from '../../services/storage/storage.service';

//#endregion

@Component({
  selector: 'cnt-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent {

  //#region Constructor

  constructor(
    private readonly storageService: StorageService,
  ) { }

  //#endregion

  //#region Properties

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter<void>()

  //#endregion

  //#region Methods

  public async proceed(): Promise<void> {
    await this.storageService.set<AcceptedInterface>(environment.storageKeys.userCookie, {
      accepted: true,
    });

    this.onClose.emit();

  }

  //#endregion

}
