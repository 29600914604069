import { version } from '../version';
import { config, storageKeys } from './config';
import { routes } from './routes';

export const environment = {
  production: false,
  staging: true,
  test: false,
  version,
  config,
  storageKeys,
  routes,
  apiBaseUrl: 'https://api-stage.conecta-facens.ligafacens.com.br',
  sentry: {
    environment: 'staging',
    dsn: 'https://ed3b928ebdd049a3ab976805eed01f79@sentry.ligafacens.com/107',
    release: version,
  },
  wordpress: {
    base: 'https://conecta.facens.br',
    showcase: '/vitrine',
    categories: '/conexoes',
  },
  ipfacens: {
    base: 'https://ipfacens.com.br/',
    contact: '/contato',
  },
  firebase: {
    apiKey: 'AIzaSyCE2InuJa3FUvoe1ChlL2gofoLGALmxTso',
    authDomain: 'staging-conectafacens.firebaseapp.com',
    projectId: 'staging-conectafacens',
    storageBucket: 'staging-conectafacens.appspot.com',
    messagingSenderId: '690631179070',
    appId: '1:690631179070:web:8a2d60ff3105b8fb1eca6f',
    measurementId: 'G-K00HZK1JBJ',
  },
};
