//#region  Imports

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { StorageResult } from '../../models/interfaces/storage-result.interface';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  //#region  Constructor

  constructor(
    private readonly storage: Storage,
  ) {
    this.createStorageInstance().then();
  }

  //#endregion

  //#region Properties

  private storageInstance: Storage | null = null;

  //#endregion

  //#region Methods

  private async createStorageInstance(): Promise<void> {
    this.storageInstance = await this.storage.create();
  }

  public async set<T>(key: string, item: T): Promise<StorageResult<T>> {
    const onSuccess: () => StorageResult<T> = () => ({ success: item });

    return this.storage.set(key, item)
      .then(onSuccess)
      .catch(async e => await this.onError(e));
  }

  public async get<T>(key: string): Promise<StorageResult<T>> {
    const onSuccess: (item: T) => StorageResult<T> = (item: T) => ({ success: item });

    return this.storage.get(key)
      .then(onSuccess)
      .catch(async e => await this.onError(e));
  }

  public async remove(key: string): Promise<StorageResult<boolean>> {
    const onSuccess: () => StorageResult<boolean> = () => ({ success: true });

    return this.storage.remove(key)
      .then(onSuccess)
      .catch(async e => await this.onError(e));
  }

  public async clear(): Promise<StorageResult<boolean>> {
    const onSuccess: () => StorageResult<boolean> = () => ({ success: true });

    return this.storage.clear()
      .then(onSuccess)
      .catch(async e => await this.onError(e));
  }

  private async onError<T>(error?: Error): Promise<StorageResult<T>> {
    if (error && error?.message)
      console.error(error, error.message);

    return { error: { message: 'Ocorreu um erro inesperado, por favor recarregue a página e tente novamente.' } };
  }

  //#endregion

}
