import { SelectionElementInterface } from '../interfaces/selection-element.interface';

export enum CategoryEnum {
  FACENS_IP = 'facens_ip',
  TCC = 'tcc',
  SCIENTIFIC_RESEARCH = 'scientific_research',
  POSTGRADUATE = 'postgraduate',
  UPX = 'upx',
  OPEN_INNOVATION = 'open_innovation',
  QUALIFICATION_OR_TRAINING = 'qualification_or_training',
  INFRASTRUCTURE = 'infrastructure',
  ACADEMIC_PROJECT = 'academic_project',
  UNKNOWN = 'unknown',
}

export const categoryToIcon: Record<CategoryEnum, string> = {
  [CategoryEnum.UNKNOWN]: 'assets/icons/unknown.svg',
  [CategoryEnum.TCC]: 'assets/icons/tcc.svg',
  [CategoryEnum.OPEN_INNOVATION]: 'assets/icons/open-innovation.svg',
  [CategoryEnum.FACENS_IP]: 'assets/icons/ip-facens.svg',
  [CategoryEnum.SCIENTIFIC_RESEARCH]: 'assets/icons/scientific-research.svg',
  [CategoryEnum.UPX]: 'assets/icons/upx.svg',
  [CategoryEnum.POSTGRADUATE]: 'assets/icons/postgraduate.svg',
  [CategoryEnum.ACADEMIC_PROJECT]: 'assets/icons/postgraduate.svg',
  [CategoryEnum.QUALIFICATION_OR_TRAINING]: 'assets/icons/qualification-or-training.svg',
  [CategoryEnum.INFRASTRUCTURE]: 'assets/icons/infrastructure.svg',
};

export const academicCategories: CategoryEnum[] = [
  CategoryEnum.UPX,
  CategoryEnum.POSTGRADUATE,
  CategoryEnum.SCIENTIFIC_RESEARCH,
  CategoryEnum.OPEN_INNOVATION,
  CategoryEnum.TCC,
];

export const categoryToString: Record<CategoryEnum, string> = {
  [CategoryEnum.UNKNOWN]: 'Desconhecido',
  [CategoryEnum.TCC]: 'TCC',
  [CategoryEnum.OPEN_INNOVATION]: 'Open Innovation',
  [CategoryEnum.FACENS_IP]: 'IP Facens',
  [CategoryEnum.SCIENTIFIC_RESEARCH]: 'IC',
  [CategoryEnum.UPX]: 'UPx',
  [CategoryEnum.POSTGRADUATE]: 'PÓS',
  [CategoryEnum.INFRASTRUCTURE]: 'Locação de espaço',
  [CategoryEnum.QUALIFICATION_OR_TRAINING]: 'Capacitação ou treinamentos',
  [CategoryEnum.ACADEMIC_PROJECT]: 'Projeto acadêmico',
};

export const proposalCategoryToSelectOptions: SelectionElementInterface<CategoryEnum>[] = [
  {
    value: CategoryEnum.UNKNOWN,
    label: categoryToString[CategoryEnum.UNKNOWN],
  },
  {
    value: CategoryEnum.TCC,
    label: categoryToString[CategoryEnum.TCC],
  },
  {
    value: CategoryEnum.OPEN_INNOVATION,
    label: categoryToString[CategoryEnum.OPEN_INNOVATION],
  },
  {
    value: CategoryEnum.FACENS_IP,
    label: categoryToString[CategoryEnum.FACENS_IP],
  },
  {
    value: CategoryEnum.ACADEMIC_PROJECT,
    label: categoryToString[CategoryEnum.ACADEMIC_PROJECT],
  },
  {
    value: CategoryEnum.QUALIFICATION_OR_TRAINING,
    label: categoryToString[CategoryEnum.QUALIFICATION_OR_TRAINING],
  },
  {
    value: CategoryEnum.INFRASTRUCTURE,
    label: categoryToString[CategoryEnum.INFRASTRUCTURE],
  },
];

export const projectCategoryToSelectOptions: SelectionElementInterface<CategoryEnum>[] = [
  {
    value: CategoryEnum.UNKNOWN,
    label: categoryToString[CategoryEnum.UNKNOWN],
  },
  {
    value: CategoryEnum.TCC,
    label: categoryToString[CategoryEnum.TCC],
  },
  {
    value: CategoryEnum.OPEN_INNOVATION,
    label: categoryToString[CategoryEnum.OPEN_INNOVATION],
  },
  {
    value: CategoryEnum.FACENS_IP,
    label: categoryToString[CategoryEnum.FACENS_IP],
  },
  {
    value: CategoryEnum.SCIENTIFIC_RESEARCH,
    label: categoryToString[CategoryEnum.SCIENTIFIC_RESEARCH],
  },
  {
    value: CategoryEnum.UPX,
    label: categoryToString[CategoryEnum.UPX],
  },
  {
    value: CategoryEnum.POSTGRADUATE,
    label: categoryToString[CategoryEnum.POSTGRADUATE],
  },
  {
    value: CategoryEnum.QUALIFICATION_OR_TRAINING,
    label: categoryToString[CategoryEnum.QUALIFICATION_OR_TRAINING],
  },
  {
    value: CategoryEnum.INFRASTRUCTURE,
    label: categoryToString[CategoryEnum.INFRASTRUCTURE],
  },
];
