//#region Imports

import { Component, Input } from '@angular/core';

//#endregion

@Component({
  selector: 'cnt-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

  //#region Properties

  @Input({ required: true })
  public showIcon: boolean = false;

  @Input({ required: false })
  public sizeInRem: number = 2;

  @Input({ required: false })
  public backdrop: boolean = false;

  //#endregion
}
