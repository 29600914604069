//#region Import

import { Component, HostBinding, Input } from '@angular/core';

//#endregion

@Component({
  selector: 'cnt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  //#region Properties

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public type: 'submit' | 'button' = 'button';

  @Input()
  public isLoading: boolean = false;

  @Input()
  public style!: 'primary' | 'secondary';

  @HostBinding('class.disabled')
  public get disabled(): boolean {
    return this.isDisabled || this.isLoading;
  }

  //#endregion
}
