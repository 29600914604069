//#region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationInteractor } from '../../interactors/authentication/authentication.interactor';
import { CompanyInteractor } from '../../interactors/company/company.interactor';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { CompanyProxy } from '../../models/proxies/company.proxy';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly userInteractor: UserInteractor,
    private readonly companyInteractor: CompanyInteractor,
    private readonly interactor: AuthenticationInteractor,
  ) { }

  //#endregion

  //#region Public properties

  public isUserAdminOrInvalid = false;

  //#endregion

  //#region Methods

  public async getUserFromStorage(): Promise<UserProxy | undefined> {
    const { success, error } = await this.storageService.get<UserProxy>(environment.storageKeys.userInformation);

    if (error)
      throw new Error(error.message);

    return success;
  }

  public async getCompanyFromStorage(): Promise<CompanyProxy> {
    const { success, error } = await this.storageService.get<CompanyProxy>(environment.storageKeys.companyInformation);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public async getUserTokenFromStorage(): Promise<TokenProxy> {
    const { error, success } = await this.storageService.get<TokenProxy>(environment.storageKeys.userToken);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public isLogged(): boolean {
    return !!this.userService.getUser();
  }

  public async logout(): Promise<void> {
    await this.storageService.clear();

    this.userService.clearUser();

    if (!this.isUserAdminOrInvalid) {
      this.router.navigate(['home']).then(() => {
        window.location.reload();
      });
    }

    this.isUserAdminOrInvalid = false;
  }

  public async closeAccount() {}

  public async login(payload: LoginPayload): Promise<UserProxy | null> {
    const { error, success: tokenProxy } = await this.interactor.login(payload);

    if (error)
      throw (this.errorService.getErrorMessage(error));

    await this.saveUserToken(tokenProxy);

    return this.userService.getUser();
  }

  public async deleteAccount(): Promise<void> {
    const { error } = await this.interactor.deleteAccount();

    if (error)
      throw (this.errorService.getErrorMessage(error));
  }

  private async saveUserToken(token?: TokenProxy): Promise<void> {
    const { error: errorOnSaveToken } = await this.storageService.set(environment.storageKeys.userToken, token);

    if (errorOnSaveToken)
      throw new Error(errorOnSaveToken.message);

    await this.getUserInformationAndUpdateLoggedUser();
  }

  public async getUserInformationAndUpdateLoggedUser(): Promise<void> {
    const { error: errorUser, success: user } = await this.userInteractor.getMe();

    if (errorUser) {
      await this.storageService.clear();

      throw (this.errorService.getErrorMessage(errorUser));
    }

    try {
      if (user)
        await this.updateLoggedUser(user);
    } catch (error) {
      throw (this.errorService.getErrorMessage(error));
    }

    if (user?.companyId) {
      const { error: errorCompany, success: company } = await this.companyInteractor.getById(user.companyId);

      if (errorCompany) {
        await this.storageService.clear();

        throw (this.errorService.getErrorMessage(errorCompany));
      }

      try {
        if (company)
          await this.updateCompany(company);
      } catch (error) {
        throw (this.errorService.getErrorMessage(error));
      }
    }
  }

  public async updateLoggedUser(user: UserProxy): Promise<void> {
    const { error: errorOnSaveUser } = await this.storageService.set(environment.storageKeys.userInformation, user);

    if (errorOnSaveUser) {
      await this.storageService.clear();
      throw new Error(errorOnSaveUser.message);
    }

    this.userService.setUser(user);
  }

  public async updateCompany(company: CompanyProxy): Promise<void> {
    await this.storageService.set(environment.storageKeys.companyInformation, company);
  }

  //#endregion

}
