import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from './guards/authentication/authentication.guard';

export const unAuthenticatedRoute: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenAuthenticated,
    unprotectedRoute: true,
  },
};

export const authenticatedRoute: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenUnauthenticated,
    protectedRoute: true,
  },
};

const authenticatedRouteWordPress: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.wordpress.base,
    routeToRedirectIfSuccess: environment.config.redirectToWhenUnauthenticated,
    protectedRoute: true,
    isExternalUrl: true,
  },
};

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/empty/empty.module').then(m => m.EmptyModule), ...authenticatedRouteWordPress },

  //route used for wordPress
  { path: 'root', loadChildren: () => import('./pages/main/my-projects/my-projects.module').then(m => m.MyProjectsModule), ...authenticatedRoute },
  { path: 'main', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
  { path: 'entrar', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), ...unAuthenticatedRoute },

  // This is used so old emails can still work with these endpoints
  { path: 'signup', title: 'Endpoint from v1', redirectTo: 'entrar/registro', pathMatch: 'full' },
  { path: 'login', title: 'Endpoint from v1', redirectTo: 'entrar', pathMatch: 'full' },

  { path: '**', redirectTo: 'entrar', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
