//#region Imports

import { Component, OnInit } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { environment } from '../environments/environment';
import { AcceptedInterface } from './models/interfaces/accepted.interface';
import { StorageService } from './services/storage/storage.service';

//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
  ) { }

  //#endregion

  //#region Properties

  public showCookieModal: boolean = false;

  //#endegion

  //#region Methods

  public async ngOnInit(): Promise<void> {
    const { success } = await this.storageService
      .get<AcceptedInterface>(environment.storageKeys.userCookie);

    if (!success)
      this.showCookieModal = true;

    //#endregion
  }
}
