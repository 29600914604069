import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/browser';
import { environment } from '../../environments/environment';

/**
 * A classe que representa o handler que lida com o envio do erro
 * para sentry.io
 */
@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor() {
    super();

    Sentry.init({
      environment: environment.sentry.environment,
      dsn: environment.sentry.dsn,
      release: environment.sentry.release,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.2,
    });
  }

  /**
   * Método que lida com o erro na aplicação
   *
   * @param error define um objeto com as informações do erro
   */
  override handleError(error: any) {
    super.handleError(error);

    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}
