export const routes = {
  auth: {
    login: '/auth/local',
    refresh: '/auth/refresh',
  },
  company: {
    byId: '/companies/{id}',
    update: '/companies/{id}',
    my: '/users/me/company',
    project: '/company-project',
    name: '/companies/{id}/name',
  },
  comment: {
    base: '/comments',
  },
  user: {
    base: '/users',
    byId: '/users/{id}',
    getMe: '/users/me',
    update: '/users/{id}',
    invitation: '/users/invitation',
    changePassword: '/users/password/{id}',
    changeEmail: '/users/email/{id}',
    password: {
      recoverPasswordByEmail: '/users/password/forgot/email/{email}',
      resetPassword: '/users/password/reset/{resetPasswordCode}',
      verifyCode: '/users/password/verify/{resetCode}',
    },
  },
  medias: {
    presigned: '/medias/presigned/',
  },
  project: {
    base: '/projects',
    byId: '/projects/{id}',
    myProjects: '/projects/my-projects',
    countByProposalStatus: '/projects/count/proposal-status',
  },
  theme: {
    base: '/themes',
  },
  location: {
    states: '/location/states',
    cities: '/location/state/{stateCode}/cities',
  },
} as const;
