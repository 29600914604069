//#region Import

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ChangePasswordPayload } from '../../models/payloads/change-password.payload';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { InviteUserPayload } from '../../models/payloads/invite-user.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { GetManyProxy } from '../../models/proxies/base/get-many.proxy';
import { ForgotPasswordProxy } from '../../models/proxies/forgot-password.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';
import { createCrudUrl, CrudRequestParams } from '../../utils/crud/crud';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.httpService.get<UserProxy>(environment.routes.user.getMe);
  }

  public async getAll(crudOptions?: CrudRequestParams<UserProxy>): Promise<GetManyProxy<UserProxy>> {
    const url = createCrudUrl<UserProxy>(environment.routes.user.base, crudOptions);

    return await this.httpService.get<UserProxy[]>(url);
  }

  public async create(payload: CreateUserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.base;

    return this.httpService.post<UserProxy>(url, payload);
  }

  public async update(id: number, payload: UpdateUserPayload) {
    const url = environment.routes.user.update.replace('{id}', id.toString());
    return this.httpService.put<UserProxy>(url, payload);
  }

  public async invite(payload: InviteUserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.invitation;

    return await this.httpService.post<UserProxy>(url, payload);
  }

  public async changePassword(id: number, password: string, newPassword: string): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.changePassword.replace('{id}', id.toString());
    const result = await this.httpService.put<UserProxy>(url, {
      currentPassword: password,
      newPassword,
    });

    return result as AsyncResult<UserProxy>;
  }

  public async changeEmail(id: number, password: string, newEmail: string): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.changeEmail.replace('{id}', id.toString());
    const result = await this.httpService.put<UserProxy>(url, {
      currentPassword: password,
      newEmail,
      confirmEmail: newEmail,
    });

    return result;
  }

  public async recoverPasswordByEmail(email: string): Promise<AsyncResult<ForgotPasswordProxy>> {
    const url = environment.routes.user.password.recoverPasswordByEmail.replace('{email}', email);

    return await this.httpService.post(url, {});
  }

  public async resetPassword(payload: ChangePasswordPayload): Promise<AsyncResult<void>> {
    const url = environment.routes.user.password.resetPassword.replace('{resetPasswordCode}', payload.code);

    return await this.httpService.post(url, {
      newPassword: payload.newPassword,
    });
  }

  public async verifyCode(code: string): Promise<AsyncResult<void>> {
    const url = environment.routes.user.password.verifyCode.replace('{resetCode}', code);

    return await this.httpService.post(url, {});
  }

  //#endregion

}
