export const config = {
  redirectToWhenAuthenticated: '/main/meus-projetos',
  redirectToWhenUnauthenticated: '/entrar',
} as const;

export const storageKeys = {
  userToken: 'USER_TOKEN',
  userInformation: 'USER_INFO',
  companyInformation: 'COMPANY_INFO',
  userCookie: 'USER_COOKIE',
} as const;
